import React from 'react';

import './LinearProgress.scss';

interface LinearProgressProps {
    value: number;
    total: number;
}
export const LinearProgress: React.FC<LinearProgressProps> = ({ value, total }) => {
    const percentage = (value / total) * 100;
    const remaining = total - value;
    return (
        <div className="linear-progress-container">
            <div style={{ width: `${percentage}%` }} className="linear-progress-bar-fill" />

            {remaining > 0 && (
                <div style={{ width: `${100 - percentage}%` }} className="linear-progress-unfilled">
                    {remaining.toFixed(2)} GB available
                </div>
            )}
        </div>
    );
};
