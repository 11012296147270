import React from 'react';

import { ObjectStatus } from '@hub-client-api';

import ConfirmActionModal from '@hub-fe/common/ConfirmActionModal';
import { SettingsTable } from '@hub-fe/common/SettingsTable';
import { TwoColLayout } from '@hub-fe/common/TwoColLayout';
import { pauseButtonDisplay } from '@hub-fe/common/displayedStatusesConfig';
import { routeToScratchpads } from '@hub-fe/common/routing/RoutingContext';
import { capitalizePhrase } from '@hub-fe/common/utils';
import { useServiceParams } from '@hub-fe/common/utils/useServiceParams';
import LedgerDomains from '@hub-fe/ledger/LedgerSettings/LedgerDomains';

import en from '../../../locales/en.json';
import { useAppActions, useAppContext } from '../AppContext';
import './ScratchpadSettings.scss';

export const ScratchpadSettings: React.FC = () => {
    const { deleteScratchpad, pauseScratchpad, resumeScratchpad } = useAppActions();
    const { activeScratchpads } = useAppContext();
    const { serviceId } = useServiceParams();
    const scratchpad = activeScratchpads.find(p => p.info.id === serviceId);
    const [showServiceActionModal, setShowServiceActionModal] = React.useState<{
        show: boolean;
        actionLabel: string;
        action?: (arg: { info: { id: string } }) => void;
    }>({ show: false, actionLabel: '' });

    if (!scratchpad) {
        return null;
    }

    const isPaused = scratchpad?.objectStatus === ObjectStatus.PAUSED;
    const status = scratchpad?.objectStatus || '';

    const onCloseModal = () => {
        setShowServiceActionModal({ show: false, actionLabel: '' });
    };
    const onDelete = async () => {
        serviceId &&
            setShowServiceActionModal({
                actionLabel: 'delete',
                show: true,
                action: async () => {
                    scratchpad && (await deleteScratchpad(scratchpad));
                    routeToScratchpads();
                },
            });
    };
    const onPause = () => {
        setShowServiceActionModal({
            actionLabel: 'pause',
            show: true,
            action: async () => {
                scratchpad && (await pauseScratchpad(scratchpad));
                onCloseModal();
            },
        });
    };
    const onResume = () => {
        scratchpad && resumeScratchpad(scratchpad);
    };

    const settings = [
        {
            title: 'Pause',
            desc: en.serviceSettings.pauseDesc,
            actionElement: (
                <button
                    onClick={isPaused ? onResume : onPause}
                    className="button secondary-smaller"
                    disabled={status === ObjectStatus.PAUSING || status === ObjectStatus.RESUMING}
                >
                    {pauseButtonDisplay[status] || 'Pause'}
                </button>
            ),
        },
        {
            title: 'Delete',
            desc: en.serviceSettings.deleteDesc,
            actionElement: (
                <button onClick={onDelete} className="button secondary-smaller warning">
                    Delete
                </button>
            ),
        },
    ];
    const col1 = (
        <>
            <h1>Settings</h1>
            <SettingsTable data={settings} />
            <div className="participant-subdomains">
                <LedgerDomains disableCustomWebDomain service="Scratchpad" />
            </div>
            <ConfirmActionModal
                onSubmitAction={() =>
                    scratchpad &&
                    showServiceActionModal.action &&
                    showServiceActionModal.action(scratchpad)
                }
                title={`${capitalizePhrase(showServiceActionModal.actionLabel)} Scratchpad`}
                action={capitalizePhrase(showServiceActionModal.actionLabel)}
                deletingAction
                bodyMessage={`Are you sure you want to ${showServiceActionModal.actionLabel} this Scratchpad?`}
                show={showServiceActionModal.show}
                onRequestClose={onCloseModal}
            />
        </>
    );
    return <TwoColLayout col1={col1} />;
};
